import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserTimes = _resolveComponent("UserTimes")!
  const _component_AddTimes = _resolveComponent("AddTimes")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_UserTimes, {
        "widget-classes": "card-xxl-stretch mb-5 mb-xl-10",
        height: 150,
        color: "success",
        title: "Wöchentliche Übersicht",
        description: "Zeiten der letzten 7 Tage",
        change: "42h"
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_AddTimes)
    ])
  ], 64))
}