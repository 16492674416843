
import {defineComponent, onMounted} from "vue";
import UserTimes from "@/components/widgets/statsistics/UserTimes.vue";
import AddTimes from "@/components/customers/AddTimes.vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "show-times",
  components: {
    AddTimes,
    UserTimes,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("dataCollection.times");
    });
  },
});
